import { writable } from 'svelte/store';

export class CarouselHelper {
  private delay = 10000;
  private maxCount = 0;
  private activeIndex = 0;
  private carouselInterval: NodeJS.Timer | undefined = undefined;

  public indexStore = writable(0);

  constructor(maximumCount: number) {
    this.maxCount = maximumCount;
  }

  public static getInstance(maximumCount: number): CarouselHelper {
    return new CarouselHelper(maximumCount);
  }

  public select = (index: number): void => {
		this.activeIndex = index;
    this.indexStore.set(this.activeIndex);
		this.resetInterval();
	};

  public handleSwipe = (event: { detail: { direction: string } }): void => {
		const newIndex = this.getSwipedIndex(event, this.activeIndex, this.maxCount);
		if (newIndex === undefined) {
			return;
		}

		this.activeIndex = newIndex;
    this.indexStore.set(this.activeIndex);
		this.resetInterval();
	};

  public resetInterval = () => {
		if (this.carouselInterval) {
			clearInterval(this.carouselInterval);
		}

		this.carouselInterval = setInterval(this.moveCarousel, this.delay);
	};

  private moveCarousel = (): void => {
		this.activeIndex = this.activeIndex + 1 >= this.maxCount ? 0 : this.activeIndex + 1;
    this.indexStore.set(this.activeIndex);
	};

  private getSwipedIndex = (event: { detail: { direction: string } }, activeIndex: number, maxCount: number): number | undefined => {
    const direction = event.detail.direction;
  
    if (direction !== 'left' && direction !== 'right') {
      return;
    }
  
    if (direction === 'left' && activeIndex === maxCount - 1) {
      return maxCount - 1;
    }
  
    if (direction === 'right' && activeIndex === 0) {
      return 0;
    }
  
    return direction === 'left'
      ? activeIndex + 1
      : activeIndex - 1;
  };
}